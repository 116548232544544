const zusatzinfo = document.querySelectorAll('.zusatzinfo');

if (zusatzinfo.length > 0){
    zusatzinfo.forEach(info => {
        info.addEventListener('click', ()=>{
            if (info.classList.contains('active')){
                info.classList.remove('active');
            } else {
                info.classList.add('active');
            }
        })
    })
}