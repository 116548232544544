import Swiper from 'swiper';
import {Controller, Autoplay, Navigation, Pagination } from 'swiper/modules';


Swiper.use([Controller, Autoplay, Navigation, Pagination]);

let slideshow = null;
let kundenStimmenSwiper = null;
let hochSlider = null;
let slider = null;


slideshow = new Swiper(".ce_rsce_slideshow .swiper-container", {
    spaceBetween: 0,
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".ce_rsce_slideshow .swiper-button-next",
        prevEl: ".ce_rsce_slideshow .swiper-button-prev",
    },
});

kundenStimmenSwiper = new Swiper(".ce_rsce_kundenstimmen .swiper-container", {
    spaceBetween: 500,
    loop: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".ce_rsce_kundenstimmen .swiper-button-next",
        prevEl: ".ce_rsce_kundenstimmen .swiper-button-prev",
    },
});

hochSlider = new Swiper(".ce_rsce_hochSlider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 50,
    loop: false,
    autoplay: {
        delay: 300000000000000,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".ce_rsce_hochSlider .swiper-button-next",
        prevEl: ".ce_rsce_hochSlider .swiper-button-prev",
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        600: {
            slidesPerView: 2,
            spaceBetween: 40,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 50,
        },
    }
});


slider = new Swiper(".ce_rsce_slider .swiper-container", {
    slidesPerView: 1,
    spaceBetween: 50,
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: true,
    },
    navigation: {
        nextEl: ".ce_rsce_slider .swiper-button-next",
        prevEl: ".ce_rsce_slider .swiper-button-prev",
    },
});



if (document.querySelector(".ce_rsce_history")) {
	document.querySelectorAll(".ce_rsce_history").forEach(historyContainer => {
		if (historyContainer.querySelectorAll(".swiper-slide").length > 2) {
			const textSwiperContainer = historyContainer.querySelector(".text-swiper-container");
			let textSwiper = null;
			let imageSwiper = null;
			textSwiper = new Swiper(textSwiperContainer, {
				direction: 'horizontal',
				navigation: {
					nextEl: historyContainer.querySelector('.swiper-button-next'),
					prevEl: historyContainer.querySelector('.swiper-button-prev'),
				},
				speed: 400,
				slidesPerView: 1,
				spaceBetween: 50,
			});
			const imageSwiperContainer = historyContainer.querySelector(".image-swiper-container");
			imageSwiper = new Swiper(imageSwiperContainer, {
				direction: 'horizontal',
				speed: 400,
				slidesPerView: 1,
				spaceBetween: 0,
				controller: {
					control: textSwiper,
					by: 'slide',
				},
			});
			imageSwiper.controller.control = textSwiper;
			textSwiper.controller.control = imageSwiper;
		}
	});
	var mouseDown = 0;
	document.addEventListener("mousedown", () => {
		++mouseDown;
	});
	document.addEventListener("mouseup", () => {
		--mouseDown;
	});
	document.querySelectorAll(".ce_rsce_history .swiper-pagination").forEach(pagination => {
		pagination.addEventListener("mousemove", function (event) {
			event.preventDefault();
			if (mouseDown != 0) {
				pagination.scrollLeft -= event.movementX;
			}
		});
	});
}

