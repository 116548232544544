const arrowDown = document.querySelectorAll('.arrowDown');

if (arrowDown.length > 0){
    arrowDown.forEach(arrowDown => {
        arrowDown.addEventListener('click', function(event){
           let selectedDiv = document.querySelector('.ce_rsce_slideshow').nextElementSibling;
            console.log(selectedDiv)

            selectedDiv.scrollIntoView({ behavior: 'smooth' });
               
            
        })
    })
}