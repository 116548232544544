const previewText = document.querySelectorAll('.ce_rsce_vorteile .preview-text strong');

const fullText = document.querySelectorAll('.ce_rsce_vorteile .full-text strong');



if (previewText.length > 0){
    previewText.forEach(preview => {
        preview.addEventListener('click', ()=> {
            let prevText = preview.parentElement.parentElement;
            let fullText = preview.parentElement.parentElement.parentElement.querySelector('.full-text');
            if (prevText.classList.contains('active')){
            } else {
                fullText.classList.add('active');
                prevText.classList.add('active');
            }
        })
    })

    fullText.forEach(full => {
        full.addEventListener('click', ()=> {
            full.parentElement.classList.remove('active');
            full.parentElement.parentElement.querySelector('.preview-text').classList.remove('active');
        })
    })
}