require("@netprofit/blockmapsfonts");

require("./scroll.js");
require("./mobileMenu.js");

require("./swiper.js");

require("./arrowDown.js");
require("./rsce_produktFilter.js");

require("./rsce_filterIcons.js");

require("./rsce_popup.js");

require("./search.js");

require("./rsce_produkte.js");

require("./rsce_newsletter.js");

require("./rsce_vorteile.js");

require("./rsce_stellenAnzeige.js");

require("./usercentrics.js");


const AOS = require("aos");

AOS.init({
    duration: 600,
    easing: 'ease-out-sine',
    startEvent: 'load'
});

document.querySelectorAll("img").forEach(img => {
    img.addEventListener("load", () => {
        AOS.refresh();
    })
})









