const popup = document.querySelector('.ce_rsce_popup');

if (popup){
    let button = popup.querySelector('.close');
    let popupContent = popup.querySelector('.content');

    button.addEventListener('click', function(){
        if (button.classList.contains('active')){
            button.classList.remove('active');
            popupContent.classList.remove('active');
        } else {
            button.classList.add('active');
            popupContent.classList.add('active');
        }
    });

    document.addEventListener('DOMContentLoaded', function(){
        setTimeout(() => {
            button.classList.add('active');
            popupContent.classList.add('active');
        },2000)
    })
}


