const filterIcon = document.querySelector('.ce_rsce_filterIcons');
const filter = document.querySelector('.ce_rsce_produktFilter');

if (filterIcon && filter){
    let icons = filterIcon.querySelectorAll('.icon');
    let text = filterIcon.querySelector('p');

    icons.forEach(icon => {
        icon.addEventListener('click', function(){
            if (icon.classList.contains('active')){
                icon.classList.remove('active');
            } else {
                icon.classList.add('active');
                let filter = icon.getAttribute("data-addfilter");
                addFilter(filter)
                //filter.scrollIntoView({ behavior: 'smooth' });
            }
           
        })
    })

    text.addEventListener('click', function(){
        filter.scrollIntoView({ behavior: 'smooth' });
    })
    
}


function addFilter(filterparam){
    if (filterparam != ""){
       
        if (filterparam.includes('=')){
            filterparam = filterparam.split('=')[1];
        }
        filterparam = filterparam.split("&");

        let filters = document.querySelector('[data-filter="kategorie"]').querySelectorAll('span');
        let saveButton = document.querySelector('.ce_rsce_produktFilter').querySelector('span.button')

        filters.forEach(filter => {
            filterparam.forEach(param => {
                let filterText = filter.innerText.replace(' ', '-');
                if (filterText.toLowerCase().includes(param)) {

                    filter.click();
                    saveButton.click();
                }
            })
        });

        
    }
}