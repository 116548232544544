
const newsletterFormular = document.querySelector('.ce_rsce_newsletter');

if (newsletterFormular){
    let showHaendlerInfo = false;
    let haendler = newsletterFormular.querySelectorAll('input[name="haendler"]');
    haendler.forEach(haendlerRadio => {
        haendlerRadio.addEventListener('change', ()=> {
            if (haendlerRadio.checked === true){
                if (haendlerRadio.value === 'Ja'){
                    showHaendlerInfo = true;
                } else {
                    showHaendlerInfo = false;
                }
            }
            showHaendlerData(showHaendlerInfo);
        })
    })
    showHaendlerData(showHaendlerInfo);

    function showHaendlerData(cond){
        if (cond === false){
            let hanedlerBox = newsletterFormular.querySelectorAll('.widget-text.hidden');
            hanedlerBox.forEach(box => {
                box.style.display = 'none';
            })
            newsletterFormular.querySelector('input[name="kooperation"]').removeAttribute('required');
            newsletterFormular.querySelector('input[name="kooperationsnummer"]').removeAttribute('required');
        } else {
            let hanedlerBox = newsletterFormular.querySelectorAll('.widget-text.hidden');
            hanedlerBox.forEach(box => {
                box.style.display = 'block';
            })
            newsletterFormular.querySelector('input[name="kooperation"]').setAttribute('required', "");
            newsletterFormular.querySelector('input[name="kooperationsnummer"]').setAttribute('required', "");
        }
    }


    // Event Listener für das Absenden des Formulars
    document.addEventListener('submit', function(event) {

        event.preventDefault();


        let eMailAnrede = newsletterFormular.querySelector('select[name="anrede"]').value;
        let eMailAdresse = newsletterFormular.querySelector('input[name="email"]').value;
        let eMailVorname = newsletterFormular.querySelector('input[name="vorname"]').value;
        let eMailNachname = newsletterFormular.querySelector('input[name="nachname"]').value;
        let eMailFirmenname = newsletterFormular.querySelector('input[name="firmenname"]').value;
        let eMailStrasse = newsletterFormular.querySelector('input[name="strasse"]').value;
        let eMailPlz = newsletterFormular.querySelector('input[name="plz"]').value;
        let eMailStadt = newsletterFormular.querySelector('input[name="stadt"]').value;
        let eMailHaendler = newsletterFormular.querySelectorAll('input[name="haendler"]');
        let isHaendler = "";
        let eMailKooperation = newsletterFormular.querySelector('input[name="kooperation"]').value;
        let eMailKooperationsnummer = newsletterFormular.querySelector('input[name="kooperationsnummer"]').value;

        eMailHaendler.forEach(radioButton => {
            if (radioButton.checked === true){
                isHaendler = radioButton.value;
            }
        })

        const form = event.target.closest('form');
        if (form) {
            if (eMailAdresse) {
                const subscriberData = {
                    email: eMailAdresse,
                    anrede: eMailAnrede,
                    firstname: eMailVorname,
                    lastname: eMailNachname,
                    company: eMailFirmenname,
                    adress: eMailStrasse,
                    plz: eMailPlz,
                    ort: eMailStadt,
                    haendler: isHaendler,
                    kooperation: eMailKooperation,
                    kooperationnumber: eMailKooperationsnummer
                };

                addSubscriber(subscriberData);
                console.log('Newsletter send');
            } else {
                console.log('Newsletter not sent: either checkbox not checked or email not entered');
            }
        }
    });

    async function addSubscriber(subscriberData) {
        try {
            const response = await fetch('cleverReachNewsletterAPI.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(subscriberData)
            });
    
            const result = await response.json();
    
            if (response.ok) {
                console.log('Erfolg:', result.success);
            } else {
                console.log('Fehler:', result.error);
            }
        } catch (error) {
            console.error('Fehler:', error.message);
        }
    }

}



