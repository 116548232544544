const urlParams = new URLSearchParams(window.location.search);
const filter = document.querySelector('.ce_rsce_produktFilter');

const produkte = document.querySelectorAll('a.produkt');

const resetFilter = document.querySelector('.filterReset');

const dimensionfilter = document.querySelector('.ce_rsce_produktFilter .dimensionfilter');

let filterObject = {
    kategorie: [],
    dimension: [],
    materialmerkmal: [],
    marke: [],
    laufrichtungbindung: []
};

if (filter) {

    let openFilter = filter.querySelectorAll('.filter p');
    let options = filter.querySelectorAll('.filter span:not(.button)');

    let saveButton = filter.querySelectorAll('.button');

    openFilter.forEach(openFilter => {
        openFilter.addEventListener('click', function(){
            if (openFilter.parentElement.classList.contains('active')){
                openFilter.parentElement.classList.remove('active');
            } else {
                openFilter.parentElement.classList.add('active');
            }
        });
    });

    options.forEach(option => {
        option.addEventListener('click', function(){
            if (option.innerText != "Speichern"){
                if (option.classList.contains('active')){
                    option.classList.remove('active');
                    showActiveCounter(option, -1);
                } else {
                    option.classList.add('active');
                    showActiveCounter(option, +1);
                }
                updateFilterObject(option);
            }
            filterProducts();
        });
        
    });

    saveButton.forEach(button =>{
        button.addEventListener('click', () => {
            filterProducts();
        });
    });

    resetFilter.addEventListener('click', () => {
        filterObject = {
            kategorie: [],
            dimension: [],
            materialmerkmal: [],
            marke: [],
            laufrichtungbindung: []
        };
        let elements = document.querySelectorAll('[data-amount]');     
        elements.forEach(element => {
            element.setAttribute('data-amount', 0);
        });
        options.forEach(option => {
            option.classList.remove('active');
            showActiveCounter(option, 0);
        });
        openFilter.forEach(filter => {
            filter.parentElement.classList.remove('active');
        });
        filterProducts();
        console.log(filterObject);

        let dimension = document.querySelectorAll('.ce_rsce_produktFilter .filter-inner[data-filter=dimension] span')
        dimension.forEach(dimension =>{
            dimension.style.display = 'block';
        });
    });



    if (urlParams.has('kategorie') || urlParams.has('merkmal') || urlParams.has('marke')){
        linkFilter();
    }

    if (dimensionfilter){
        dimensionfilter.addEventListener('input', dimensionFilter);
    }
}

function dimensionFilter(){
    let search = dimensionfilter.value;
    let dimension = document.querySelectorAll('.ce_rsce_produktFilter .filter-inner[data-filter=dimension] span')
    dimension.forEach(dimension =>{
        if (dimension.innerText.includes(search) === false){
            dimension.style.display = 'none';
        } else {
            dimension.style.display = 'block';
        }
    })
}

function showActiveCounter(option, amount){
    let filterName = option.parentElement.parentElement.querySelector('p');
    let output = option.parentElement.parentElement.querySelector('.counter');
    let count = filterName.getAttribute('data-amount');
    count = parseInt(count) + parseInt(amount);
    filterName.setAttribute('data-amount', count);
    if (count > 0){
        output.innerText = count;
        output.style.display = 'block';
    } else {
        output.innerText = '';
        output.style.display = 'none';
    }
}

function updateFilterObject(option){
    let item = option.innerText.replace(/[\s\/.]+/g, '');
    let categoryElement = option.closest('.filter');
    let category = categoryElement.querySelector('p').innerText.toLowerCase().replace(/\s+/g, '');

    if (filterObject[category].includes(item)){
        let index = filterObject[category].indexOf(item);
        filterObject[category].splice(index, 1); 
    } else {
        filterObject[category].push(item);
    }
    console.log(filterObject);
}

function filterProducts(){
    produkte.forEach(produkt => {
        let produktItems = produkt.getAttribute('produkt-filter');
        console.log(produktItems);

        let showProdukt = true;

        for (let category in filterObject) {
            if (filterObject[category].length > 0) {
                let match = filterObject[category].some(filter => produktItems.includes(filter));
                if (!match) {
                    showProdukt = false;
                    break;
                }
            }
        }

        if (showProdukt) {
            produkt.style.display = 'block';
        } else {
            produkt.style.display = 'none';
        }
    });
}



function linkFilter(){
    let kategorie;
    let merkmal;
    let marke;

    if (urlParams.has('kategorie')) {
        kategorie = urlParams.get('kategorie');
        let filters = document.querySelector('[data-filter="kategorie"]').querySelectorAll('span');

        filters.forEach(filter => {
            if (filter.innerText.toLowerCase().includes(kategorie)) {
                filter.click();
            }
        });
    }

    if (urlParams.has('merkmal')) {
        merkmal = urlParams.get('merkmal');
        let filters = document.querySelector('[data-filter="merkmal"]').querySelectorAll('span');

        filters.forEach(filter => {
            if (filter.innerText.toLowerCase().includes(merkmal)) {
                filter.click();
            }
        });
    }

    if (urlParams.has('marke')) {
        marke = urlParams.get('marke');
        let filters = document.querySelector('[data-filter="marke"]').querySelectorAll('span');

        filters.forEach(filter => {
            if (filter.innerText.toLowerCase().includes(marke)) {
                filter.click();
            }
        });
    }


    filterProducts();
}




