const search = document.querySelector('.container-inner.search .search');

if (search){
    search.addEventListener('click', ()=> {
        if (search.classList.contains('active')){
            search.classList.remove('active');
        } else {
            search.classList.add('active');
        }
    });

    let searchform = search.querySelector('form');
    let searchbutton = search.querySelector('.searchbutton');

    if (searchbutton){
        searchbutton.addEventListener('click', () => {
            searchform.submit();
        })
    }
}