window.addEventListener('load', function(){


    let buttonBackground = '#054794';
    let buttonColor = '#ffffff';
    let buttonBorderColor = 'transparent';

    let buttonHoverBackground = '#ffffff';
    let buttonHoverColor = '#054794';
    let buttonHoverBorderColor = '#054794';
    
    const userCentricsRoot = document.querySelector('#usercentrics-root');
    const sheet = new CSSStyleSheet;
    
    sheet.replaceSync(
      `
      #uc-center-container { border-radius: 2px !important }
      #focus-lock-id div:nth-child(2) { backdrop-filter: blur(3px) }
      [data-testid="uc-header"] > div:first-of-type,
      [data-testid="uc-header"] > div:nth-of-type(2) { display: none !important }
      #uc-show-more { padding: 0 !important }
      [data-testid="uc-anchors"] a { text-decoration: underline !important }
      [data-testid="uc-footer"] button {
        background-color: ${buttonBackground} !important;
        color: ${buttonColor} !important;
        border: 1px solid ${buttonBorderColor};
        transition: all .2s linear 0s !important;
      }
      [data-testid="uc-footer"] button:hover { 
        background-color: ${buttonHoverBackground} !important;
        color: ${buttonHoverColor} !important;
        border: 1px solid ${buttonHoverBorderColor}!important;
      }
      [data-testid="first-line-buttons"] { flex-direction: column !important }
      button[data-testid="uc-accept-all-button"] {
        background-color: ${buttonBackground} !important;
        color: ${buttonColor} !important;
        border: 1px solid ${buttonBorderColor};
        transition: all .5s!important;
      }
      button[data-testid="uc-accept-all-button"]:hover {
        background-color: ${buttonHoverBackground} !important;
        color: ${buttonHoverColor} !important;
        border: 1px solid ${buttonHoverBorderColor}!important;
      }
      button[data-testid="uc-accept-all-button"]:before {
        content: "✓";
        padding-right: 6px;
      }
      button[data-testid="uc-deny-all-button"]:before {
        content: "✗";
        padding-right: 6px;
      }
      button[data-testid="uc-deny-all-button"] { order: 2 }
      button[data-testid="uc-accept-all-button"] { order: 1 }
      button[data-testid="uc-more-button"] { order: 3 }
      button[data-testid="uc-save-button"] { order: 3 }
      [data-testid="uc-footer"] div:nth-child(2) { opacity: .7 !important }
      [data-testid="uc-default-wall"] > div:last-of-type { background-color: transparent !important }
      [data-testid="uc-vertical-scroller"],
      [data-testid="uc-header-wrapper"] { background-color: transparent !important }
      [data-testid="uc-privacy-button"] {
        background-color: #5d7380 !important;
        bottom: 10px !important;
        left: 10px !important;
        width: 30px !important;
        height: 30px !important;
      }
      [data-testid="uc-privacy-button"] div { padding: 3px }
      [data-testid="uc-banner-content"] { background-color: #ffffff !important; }
      `
    );
    
    userCentricsRoot.shadowRoot.adoptedStyleSheets = [sheet];
    
})