const stellenAnzeige = document.querySelector('.ce_rsce_stellenAnzeige');

if (stellenAnzeige){
    let stellenAnzeigen = stellenAnzeige.querySelectorAll('.stellenanzeige');

    stellenAnzeigen.forEach(stellenAnzeige => {
        stellenAnzeige.addEventListener('click', () => {
            let innerElement = stellenAnzeige.querySelector('.stellenanzeige-inner');
            if (innerElement.classList.contains('active')){
                stellenAnzeige.classList.remove('active');
                innerElement.classList.remove('active');
            } else {
                stellenAnzeige.classList.add('active');
                innerElement.classList.add('active');
            }
        })
    })
}