const menuButton = document.querySelector('.menuButton');
const mobileMenu = document.querySelector('.mobile');

const submenu = document.querySelectorAll('li.submenu');

if (menuButton){
    menuButton.addEventListener('click', () => {
        if (menuButton.classList.contains('active')){
            menuButton.classList.remove('active');
            mobileMenu.classList.remove('active');
        } else {
            menuButton.classList.add('active');
            mobileMenu.classList.add('active');
        }
    });

    submenu.forEach(menu => {
        menu.addEventListener('click', ()=> {
            if (menu.classList.contains('open')){
                menu.classList.remove('open');
            } else {
                menu.classList.add('open');
            }
        })
    })
}